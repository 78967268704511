import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

const baseUrl = process.env.NODE_ENV === 'production'
  ? 'https://core.sokxayallplus.com'
  : 'https://core.sokxayallplus.com'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseUrl
})

/**
 * REQUEST INTERCEPTOR
 */

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('SCPPORTAL_ACCESS_TOKEN')
    // @ts-ignore
    config.headers.Authorization = token || undefined
    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

export default axiosInstance
